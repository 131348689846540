import React from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { rem } from 'polished';
import styled from 'styled-components';

import { Button } from '~/components/button';
import { Heading } from '~/components/heading';
import { Paragraph } from '~/components/paragraph';
import { borderRadius, boxShadow700, boxShadow900, colors } from '~/theme';

const Card = styled.div`
  background: ${colors.white};
  border-radius: ${borderRadius.md};
  box-shadow: ${boxShadow700};
  overflow: hidden;
  position: relative;
  width: 100%;
  min-height: 100%;
  box-sizing: border-box;
  display: flex;

  img {
    margin-bottom: 0;
  }
`;

const Content = styled.div`
  margin: ${rem(36)} ${rem(32)};
  display: flex;
  flex-direction: column;

  > :last-child {
    margin-top: auto;
  }
`;

const SmallSVG = styled.div`
  margin-bottom: ${rem(5)};
  min-height: ${rem(55)};

  img {
    width: ${rem(48)};
    height: ${rem(48)};
  }
`;

const BigSVG = styled.div`
  opacity: 0.5;
  position: absolute;
  right: ${-196 / 2}px;
  top: ${-196 / 4}px;

  img {
    width: ${rem(196)};
    height: ${rem(196)};
  }
`;

export const ServicesCard = ({
  isIconReversed,
  isIconFlipped,
  headingText,
  paragraphText,
  icon,
  link,
}) => {
  const { t } = useTranslation();

  return (
    <Card>
      <BigSVG isIconReversed={isIconReversed} isIconFlipped={isIconFlipped}>
        <img src={icon?.localFile?.publicURL} alt={icon?.altText} />
      </BigSVG>

      <Content>
        <SmallSVG>
          <img src={icon?.localFile?.publicURL} alt={icon?.altText} />
        </SmallSVG>

        <Heading tag="h3" size="h6" spacing="md">
          {headingText}
        </Heading>
        <Paragraph size="sm">{paragraphText}</Paragraph>

        <Button size="md" variation="ghost" link={link}>
          {t('services.readMore')}
        </Button>
      </Content>
    </Card>
  );
};
