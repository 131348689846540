import React from 'react';
import { rem } from 'polished';
import styled from 'styled-components';

import { mediaQuery } from '~/theme';

const SectionWrapper = styled.section`
  padding-top: ${rem(128)};
  position: relative;
  overflow-x: hidden;

  ${mediaQuery('xl')} {
    padding-top: ${rem(96)};
  }

  ${mediaQuery('md')} {
    padding-top: ${rem(64)};
  }

  ${mediaQuery('sm')} {
    padding-top: ${rem(48)};
  }
`;

export const Section = ({ children, className, id }) => (
  <SectionWrapper className={className} id={id}>
    {children}
  </SectionWrapper>
);
