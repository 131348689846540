import React, { useContext } from 'react';
import Div100vh from 'react-div-100vh';
import { Col, Row } from 'react-styled-flexboxgrid';
import { motion } from 'framer-motion';
import { rem } from 'polished';
import styled from 'styled-components';

import { Button } from '~/components/button';
import { Heading } from '~/components/heading';
import { Paragraph } from '~/components/paragraph';
import { useStaggerReveal } from '~/core/hooks';
import { AppContext } from '~/layout/context';
import { colors, Container, mediaQuery, zIndexes } from '~/theme';

export const Hero = ({ title, description, buttonText }) => {
  const { isAnimationDone, setRequestModal } = useContext(AppContext);
  const { getStaggerAnimationProps } = useStaggerReveal(0);

  return (
    <VideoContainer>
      <Video playsInline autoPlay muted loop>
        {/*<source*/}
        {/*  src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/4273/fashion.webm"*/}
        {/*  type="video/webm"*/}
        {/*/>*/}
        <source
          src="https://res.cloudinary.com/dugps1nr7/video/upload/v1619646826/hero-video_fdnhzg.mp4"
          type="video/mp4"
        />
      </Video>
      {isAnimationDone && (
        <VideoContentWrapper>
          <Row center="lg">
            <Col lg={8} xs={12}>
              <Heading
                tag="h1"
                size="h1"
                color={colors.gray100}
                {...getStaggerAnimationProps(1)}
              >
                {title}
              </Heading>
              <Paragraph
                color={colors.gray200}
                size="lg"
                {...getStaggerAnimationProps(2)}
              >
                {description}
              </Paragraph>
              <Button
                size="lg"
                variation="fill"
                isDark
                onClick={() => setRequestModal(true)}
                {...getStaggerAnimationProps(3)}
              >
                {buttonText}
              </Button>
            </Col>
          </Row>
        </VideoContentWrapper>
      )}
    </VideoContainer>
  );
};

const VideoContainer = styled(Div100vh)`
  width: 100vw;
  overflow: hidden;
  position: relative;
  margin-top: ${rem(-72)};

  &::after {
    content: '';
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    background: linear-gradient(
      0deg,
      ${colors.blue900} 0%,
      ${colors.gray900} 50%
    );
    opacity: 0.7;
  }
`;

const Video = styled.video`
  height: 101%;
  width: 100%;
  object-fit: cover;
  position: absolute;

  &:focus {
    outline: none;
  }
`;

const VideoContentWrapper = styled(motion(Container))`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: ${zIndexes.hero};
  display: flex;
  align-items: center;
  justify-content: center;

  ${mediaQuery('sm')} {
    align-items: flex-end;
    padding-bottom: ${rem(48)};
  }

  ${Col} {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;

    > *:last-child {
      margin-top: ${rem(24)};

      ${mediaQuery('sm')} {
        margin-top: ${rem(8)};
      }
    }

    ${mediaQuery('sm')} {
      text-align: left;
      align-items: flex-start;
    }
  }
`;
