import React from 'react';
import { Col, Row } from 'react-styled-flexboxgrid';
import { motion } from 'framer-motion';
import { GatsbyImage } from 'gatsby-plugin-image';
import { rem } from 'polished';
import styled, { css } from 'styled-components';

import { FadeIn } from '~/components/animations';
import { Button } from '~/components/button';
import { Heading } from '~/components/heading';
import { Paragraph } from '~/components/paragraph';
import { Section } from '~/components/section';
import { Subheading } from '~/components/subheading';
import { useAnimationOnViewRef } from '~/core/hooks';
import { borderRadius, Container, mediaQuery } from '~/theme';

const textVariants = {
  hidden: {
    opacity: 0,
    y: 32,
    transition: {
      duration: 0.25,
      delay: 0,
    },
  },
  visible: i => ({
    opacity: 1,
    y: 0,
    transition: {
      delay: i * 0.15,
      duration: 0.25,
      ease: [0.24, 0.1, 0.25, 1],
    },
  }),
};

export const Feature = ({
  id,
  image,
  imageAlt,
  title,
  subtitle,
  description,
  buttonText,
  buttonLink,
  buttonOnClick,
  className,
  isReverse,
  icons,
  icon,
  children,
}) => {
  const [contentRef, controls] = useAnimationOnViewRef(0.3, 0);

  const getTextAnimationProps = index => ({
    variants: textVariants,
    animate: controls,
    initial: 'hidden',
    custom: index,
  });

  return (
    <Section className={className} id={id}>
      {children}
      <Container>
        <Row reverse={isReverse} between="lg">
          <Image xs={12} lg={7} isReverse={isReverse}>
            <FadeIn>
              <GatsbyImage image={image} alt={imageAlt} />
            </FadeIn>
          </Image>

          <Content xs={12} lg={5} ref={contentRef}>
            {icons ? (
              <IconsContainer {...getTextAnimationProps(1)}>
                {icons.map(icon => (
                  <GatsbyImage
                    key={icon.image}
                    image={icon.image}
                    alt={icon.altText}
                  />
                ))}
              </IconsContainer>
            ) : icon ? (
              <motion.img
                {...getTextAnimationProps(1)}
                src={icon.src}
                alt={icon.alt}
                style={{
                  width: rem(48),
                  height: rem(48),
                  margin: `0 0 ${rem(8)}`,
                }}
              />
            ) : (
              <Subheading {...getTextAnimationProps(1)}>{subtitle}</Subheading>
            )}

            <Heading tag="h3" size="h4" {...getTextAnimationProps(2)}>
              {title}
            </Heading>

            <Paragraph {...getTextAnimationProps(3)}>{description}</Paragraph>

            <Button
              size="lg"
              variation={icons || icon ? 'outline' : 'ghost'}
              link={!buttonOnClick && buttonLink}
              onClick={buttonOnClick}
              {...getTextAnimationProps(4)}
            >
              {buttonText}
            </Button>
          </Content>
        </Row>
      </Container>
    </Section>
  );
};

const Content = styled(Col)`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${mediaQuery('lg')} {
    margin-top: ${rem(48)};
  }

  ${mediaQuery('md')} {
    margin-top: ${rem(32)};
  }

  ${mediaQuery('sm')} {
    margin-top: ${rem(24)};
  }
`;

const IconsContainer = styled(motion.div)`
  margin-bottom: ${rem(8)};
`;

const Image = styled(Col)`
  img {
    border-radius: ${borderRadius.lg};
  }

  ${props =>
    props.isReverse
      ? css`
          padding: 0 0 0 ${rem(32)};
        `
      : css`
          padding: 0 ${rem(32)} 0 0;
        `};

  ${mediaQuery('lg')} {
    padding: 0;
  }
`;
