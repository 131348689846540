import React from 'react';
import { Col, Row } from 'react-styled-flexboxgrid';
import { useI18next } from 'gatsby-plugin-react-i18next';
import { rem } from 'polished';
import styled from 'styled-components';

import { FadeIn } from '~/components/animations';
import { ServicesCard } from '~/components/services-card/services-card';
import { getFirstSentence } from '~/core/helpers';
import { getServiceLink } from '~/core/links';
import { Container } from '~/theme';

const FadeInStyled = styled(FadeIn)`
  height: 100%;
`;
export const ServicesList = ({ services }) => {
  const { language } = useI18next();

  return (
    <Container>
      <Row center="xs">
        {services.map(({ serviceFields: service }, index) => (
          <Col
            key={service.title}
            xl={3}
            lg={4}
            md={6}
            xs={12}
            style={{
              marginBottom: rem(32),
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <FadeInStyled delay={0.25 + index * 0.1}>
              <ServicesCard
                isIconReversed
                headingText={service.title}
                paragraphText={getFirstSentence(service.description)}
                icon={service.icon}
                link={`${getServiceLink(language)}-${index + 1}`}
              />
            </FadeInStyled>
          </Col>
        ))}
      </Row>
    </Container>
  );
};
