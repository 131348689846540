import React, { useEffect, useMemo, useState } from 'react';
import { rem } from 'polished';
import {
  ButtonBack,
  ButtonNext,
  CarouselProvider,
  Slide,
  Slider,
} from 'pure-react-carousel';
import styled from 'styled-components';

import 'pure-react-carousel/dist/react-carousel.es.css';

import { useWindowSize } from '~/core/hooks';
import { ReactComponent as Arrow } from '~/static/svg/arrow-right.svg';

const CarouselWrapper = styled.div`
  .slider__back,
  .slider__next {
    &:disabled {
      opacity: 0.2;
    }
  }

  .slider__back {
    background: transparent;
    border: none;
    margin-right: ${rem(16)};
    outline: none;
    transform: rotateY(-180deg);
    transition: all 0.1s ease-in-out;

    &:hover {
      &:not([disabled]) {
        cursor: pointer;
        transform: scale(1.2) rotateY(-180deg);
      }
    }
  }

  .slider__next {
    background: transparent;
    border: none;
    outline: none;
    transition: transform 0.1s ease-in-out;

    &:hover {
      &:not([disabled]) {
        cursor: pointer;
        transform: scale(1.3);
      }
    }
  }
`;

const ButtonWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin: ${rem(10)} 0 0 0;
`;

const SliderWrapper = styled.div`
  height: ${rem(430)};
  overflow: hidden;
  width: 100%;

  .slider {
    padding-top: ${rem(24)};
  }
`;

export const Carousel = ({ items }) => {
  const { width: windowWidth } = useWindowSize();
  const [visibleSlides, setVisibleSlides] = useState(0);

  const getVisibleSlidesCount = () => {
    if (windowWidth < 700) {
      return 1;
    }

    if (windowWidth < 900) {
      return 2;
    }

    if (
      (windowWidth < 1200 && items.length >= 2) ||
      (windowWidth > 1200 && items.length === 2)
    ) {
      return 3;
    }

    if (windowWidth > 1200 && items.length >= 3) {
      return 4;
    }

    return 4;
  };

  useEffect(() => {
    setVisibleSlides(getVisibleSlidesCount());
  }, [windowWidth]);

  return (
    <CarouselWrapper>
      <CarouselProvider
        naturalSlideWidth={200}
        naturalSlideHeight={700}
        totalSlides={items.length}
        visibleSlides={visibleSlides}
      >
        <SliderWrapper>
          <Slider className="slider">
            {items.map((item, index) => (
              <Slide index={index} key={item}>
                {item}
              </Slide>
            ))}
          </Slider>
        </SliderWrapper>
        {items.length > visibleSlides && (
          <ButtonWrapper>
            <ButtonBack className="slider__back">
              <Arrow />
            </ButtonBack>
            <ButtonNext className="slider__next">
              <Arrow />
            </ButtonNext>
          </ButtonWrapper>
        )}
      </CarouselProvider>
    </CarouselWrapper>
  );
};
