import React from 'react';
import { motion } from 'framer-motion';
import { rem } from 'polished';
import styled from 'styled-components';

import { ConditionalWrap } from '~/components/animations/conditional-wrap';
import { isObjectEmpty } from '~/core/helpers';
import { colors } from '~/theme';

const Text = styled(motion.h3)`
  align-items: center;
  color: ${colors.blue500};
  display: flex;
  font-size: ${rem(12)};
  font-weight: 600;
  letter-spacing: ${rem(2)};
  line-height: 1;
  margin: 0 0 ${rem(8)};
  text-transform: uppercase;

  &::before {
    background-color: ${colors.blue500};
    content: '';
    display: block;
    height: ${rem(2)};
    margin-right: ${rem(8)};
    width: ${rem(32)};
  }
`;

// tag: h1, h2, h3, h4, h5, h6
export const Subheading = ({ tag, children, ...animationProps }) => {
  return (
    <ConditionalWrap
      condition={!isObjectEmpty(animationProps)}
      {...animationProps}
    >
      <Text as={tag}>
        <span>{children}</span>
      </Text>
    </ConditionalWrap>
  );
};
