import React, { useState } from 'react';
import { graphql } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import { useI18next } from 'gatsby-plugin-react-i18next';
import SimpleReactLightbox from 'simple-react-lightbox';
import styled from 'styled-components';

import { FadeIn } from '~/components/animations';
import { Carousel } from '~/components/carousel';
import { Certificate } from '~/components/certificate';
import { Feature } from '~/components/feature';
import { Hero } from '~/components/hero';
import { LightBox } from '~/components/lightbox';
import { PageHeading } from '~/components/page-heading';
import { Section } from '~/components/section';
import { ServicesList } from '~/components/services-card/services-list';
import { getMappedHeading } from '~/core/data-mappers';
import { getMappedCertificates } from '~/core/data-mappers';
import { getLink } from '~/core/links';
import { ReactComponent as MapIllustration } from '~/static/svg/map.svg';
import { ContactSection } from '~/templates/components/contact-section';
import { Container } from '~/theme';
import { Seo } from 'gatsby-plugin-wpgraphql-seo';

const AboutIllustration = styled(MapIllustration)`
  position: absolute;
  top: 0;
  left: 0;
`;

const HomeTemplate = ({ data }) => {
  const {
    allWpCertificate: { certificatesNodes },
    services: { nodes: services },
    certificatesPage,
    contactPage,
    pageData: {
      HomeFields: { about, hero, projects, servicesPage },
    },
    pageData,
  } = data;
  const { language } = useI18next();

  const certificatesHeading = getMappedHeading(certificatesPage);
  const contactHeading = getMappedHeading(contactPage);
  const certificates = getMappedCertificates(certificatesNodes);
  const [activeIndex, setActiveIndex] = useState(null);

  const createCertificatesList = () => {
    const newArray = [];
    certificates.map((certificate, index) =>
      newArray.push(
        <Certificate
          isSmall
          certificate={certificate}
          onClick={() => setActiveIndex(index)}
        />
      )
    );
    return newArray;
  };

  return (
    <SimpleReactLightbox>
      <Seo post={pageData} />

      <Hero
        title={hero.title}
        description={hero.description}
        buttonText={hero.buttonText}
      />

      <Feature
        image={getImage(about.image.localFile)}
        imageAlt={about.image.altText}
        title={about.title}
        subtitle={about.subtitle}
        description={about.description}
        buttonText={about.buttonText}
        buttonLink={getLink('about', language)?.url}
        isReverse
      >
        <AboutIllustration />
      </Feature>

      <Feature
        image={getImage(projects.image?.localFile)}
        imageAlt={projects.image?.altText}
        title={projects.title}
        subtitle={projects.subtitle}
        description={projects.description}
        buttonText={projects.buttonText}
        buttonLink={getLink('projects', language)?.url}
      />
      {certificates.length > 0 && (
        <Section>
          <PageHeading
            description={certificatesHeading.description}
            title={certificatesHeading.title}
            headingTag="h2"
          />
          <FadeIn>
            <Container>
              <Carousel items={createCertificatesList()} />
            </Container>
          </FadeIn>
        </Section>
      )}

      <Section>
        <PageHeading
          description={servicesPage.description}
          title={servicesPage.title}
          headingTag="h2"
        />

        <ServicesList services={services} />
      </Section>

      <Section>
        <PageHeading
          description={contactHeading.description}
          title={contactHeading.title}
          headingTag="h2"
        />

        <ContactSection />
      </Section>

      <LightBox
        images={certificates.map(cerf => cerf.image)}
        activeIndex={activeIndex}
        setActiveIndex={setActiveIndex}
      />
    </SimpleReactLightbox>
  );
};

export const query = graphql`
  query($slug: String, $locale: String, $lng: String) {
    allWpCertificate(filter: { locale: { locale: { eq: $locale } } }) {
      certificatesNodes: nodes {
        title
        certificatesFields {
          description
          image {
            localFile {
              thumbnail: childImageSharp {
                gatsbyImageData(height: 320, placeholder: BLURRED)
              }
              full: childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
              placeholder: childImageSharp {
                gatsbyImageData(height: 4, placeholder: BLURRED)
              }
            }
          }
        }
      }
    }
    services: allWpService(filter: { locale: { locale: { eq: $locale } } }) {
      nodes {
        serviceFields {
          title
          description
          icon {
            altText
            localFile {
              publicURL
            }
          }
        }
      }
    }
    certificatesPage: wpPage(
      slug: { eq: "certificates" }
      locale: { locale: { eq: $locale } }
    ) {
      ...PageHeadingFragment
    }
    contactPage: wpPage(
      slug: { eq: "contact" }
      locale: { locale: { eq: $locale } }
    ) {
      ...PageHeadingFragment
    }
    pageData: wpPage(slug: { eq: $slug }, locale: { locale: { eq: $locale } }) {
      ...YoastSiteFragment
      HomeFields {
        about {
          buttonText
          description
          subtitle
          title
          image {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(height: 1024, placeholder: BLURRED)
              }
            }
          }
        }
        hero {
          buttonText
          description
          title
        }
        projects {
          title
          subtitle
          description
          buttonText
          image {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(height: 1024, placeholder: BLURRED)
              }
            }
          }
        }
        servicesPage: services {
          title
          description
        }
      }
    }
    locales: allLocale(filter: { language: { eq: $lng } }) {
      edges {
        node {
          ...TranslationFragment
        }
      }
    }
  }
`;

export default HomeTemplate;
