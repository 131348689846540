import React, { useState } from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { rem } from 'polished';
import styled, { css } from 'styled-components';

import { Heading } from '~/components/heading';
import { ReactComponent as Magnifier } from '~/static/svg/magnifier.svg';
import {
  borderRadius,
  boxShadow800,
  colors,
  imageOverlay,
  mediaQuery,
} from '~/theme';

const CertificateContainer = styled.div`
  align-items: center;
  border: ${rem(3)} solid ${colors.gray300};
  border-radius: ${borderRadius.sm};
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: ${({ isSmall }) => (isSmall ? rem(240) : rem(304))};
  padding: 0 ${rem(24)} 0;
  position: relative;
  margin: ${rem(32)} auto ${rem(24)};
  transition: border 0.25s ease-in-out;

  &:hover {
    ${({ isHovered }) =>
      isHovered &&
      css`
        border: ${rem(3)} solid ${colors.blue900};
      `}
  }
`;

const ImageWrapper = styled.div`
  box-shadow: ${boxShadow800};
  display: flex;
  height: ${({ isSmall }) => (isSmall ? rem(264) : rem(300))};
  position: relative;
  overflow: hidden;
  background: transparent;

  ${imageOverlay}
`;

const CertificateTitle = styled(Heading)`
  && {
    margin: ${rem(-16)} 0 ${rem(24)};
    text-align: center;
    font-size: ${props => props.isSmall && rem(18)};

    ${mediaQuery('xl')} {
      font-size: ${({ isSmall }) => (isSmall ? rem(16) : rem(20))};
    }
  }
`;

const IconWrapper = styled.div`
  left: 50%;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: opacity 0.25s ease-in-out;
  z-index: 2;
`;

const Content = styled.div`
  position: relative;
  top: ${rem(-32)};

  &:hover {
    ${IconWrapper} {
      opacity: 1;
    }
  }
`;

export const Certificate = ({ certificate, onClick, isSmall }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <CertificateContainer isHovered={isHovered} isSmall={isSmall}>
      <Content>
        <ImageWrapper
          isSmall={isSmall}
          onClick={onClick}
          isHovered={isHovered}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <GatsbyImage
            image={certificate.image.thumbnail}
            alt={certificate.title}
            css={`
              height: 100%;
            `}
          />
          <IconWrapper>
            <Magnifier />
          </IconWrapper>
        </ImageWrapper>
      </Content>
      <CertificateTitle
        isSmall={isSmall}
        tag="h6"
        size="h6"
        spacing="md"
        color={colors.blue900}
      >
        {certificate.title}
      </CertificateTitle>
    </CertificateContainer>
  );
};
